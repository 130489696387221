import { onUnmounted, ref, watch } from '@vue/composition-api'
import { getIndex } from '@core/utils'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/orders/itemStoreModule'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-order'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })
  const blankItem = {
    id: null,
    order_date: null,
    order_reference: null,
    expedition_date: null,
    printer_id: null,
    destination_id: null,
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const signatureForm = ref({})
  const canEdit = (th, td) => {
    if (td && th.value && td.editable) {
      td.classes = td.classes || {}
      td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
    } else {

    }
  }
  const tableCommonColumns = [
  ]
  const tableColumns = [
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      class: 'fixed-column actions 50px l-50',
      cellClass: 'fixed-column actions 50px l-50 py-1',
      width: '50px',
      sortable: false,
    },
    {
      text: 'Date',
      value: 'order_date',
      align: 'center',
      width: "100px",
      class: 'text-center  fixed-column text-center Auteur mineur 100px l-100',
      cellClass: ' text-center fixed-column  100px l-100',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      group: true,
      info: 'Confirmer le traitement de "Date" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'order_date', required: false, noLimit: true
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'order_date',
          text: 'Date',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'order_date',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-order_date',
          column: 'order_date',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /*canEdit,*/
      ],
    },
    {
      text: 'Mois',
      value: 'order_date1',
      column: 'order_date',
      align: 'center',
      class: 'text-center fixed-column Auteur mineur 50px l-200',
      cellClass: 'text-center fixed-column  50px l-200',
      width: '100px',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Mois" ?',
      fields: [
        /*{
          type: 'date', label: 'Date de traitement', value: 'order_date', required: false, noLimit: true
        },*/
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'order_date1',
          text: 'Date',
          place_holder: 'Date1 - Date2',
          column: 'order_date',
          type: 'date',
          menu: false,
          range: true,
          month: true,
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'order_date',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-order_date',
          column: 'order_date',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /*canEdit,*/
      ],
    },
    {
      text: 'Ordre',
      value: 'user.code',
      fixed: true,
      class: 'text-center fixed-column 100px l-300',
      cellClass: 'text-center fixed-column  100px l-300',
      width: '100px',
      sortable: false,
      align: 'center',
      colors: 'order_colors',
      comments: 'order_comments',
      hideEdit: true,
      editable: true,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'users.id',
        join_table: 'users',
        join_column: 'users.id',
        local_column: 'orders.user_id',
      },
      filters: [
        {
          text: 'Ordre',
          place_holder: 'Ordre',
          type: 'select',
          values: [],
          label: 'code',
          id: 'id',
          multiple: true,
          key: 'users.id',
          column: 'users.id',
          join_table: 'users',
          join_column: 'users.id',
          local_column: 'orders.user_id',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'user.code',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-user_id',
          column: 'user_id',
        },
      ],
    },
    {
      text: 'Référence',
      align: 'center',
      value: 'order_reference',
      fixed: true,
      class: 'text-center fixed-column 100px l-400',
      cellClass: 'text-center fixed-column  100px l-400',
      width: '100px',
      colors: 'order_colors',
      comments: 'order_comments',
      type: 'text',
      editable: true,
      info: 'Confirmer le traitement de "Numéro de référence" ?',
      fields: [
        {
          type: 'text', label: 'Numéro de référence', value: 'order_reference', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'order_reference',
          text: 'Numéro de référence',
          place_holder: 'Numéro de référence',
          type: 'text',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'order_reference',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-order_reference',
          column: 'order_reference',
        },
      ],
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Imprimeur',
      value: 'printer.name',
      column_name: 'printer_id',
      align: 'center',
      class: 'text-center fixed-column 100px l-500',
      cellClass: 'text-center fixed-column  100px l-500',
      width: '100px',
      fixed: true,
      action: 'printer.label',
      viewPermission: 'printer',
      editPermission: 'edit_printer',
      colors: 'order_colors',
      comments: 'order_comments',
      color_column: 'printer_id',
      comment_column: 'printer_id',
      editable: true,
      info: 'Confirmer le traitement de "Imprimeur" ?',
      fields: [
        {
          type: 'select',
          label: 'Imprimeur',
          value: 'printer_id',
          required: false,
          items: store.state.app.printers,
          item_text: 'name',
          item_value: 'id',
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          text: 'Imprimeur',
          place_holder: 'Imprimeur',
          type: 'select',
          values: store.state.app.printers,
          label: 'name',
          id: 'id',
          multiple: true,
          key: 'printers.id',
          column: 'printers.id',
          join_table: 'printers',
          join_column: 'printers.id',
          local_column: 'orders.printer_id',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'printer_id',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-printer_id',
          column: 'printer_id',
        },
      ],
    },
    {
      text: 'Dossier',
      align: 'center',
      value: 'publication_id',
      fixed: true,
      type: 'number',
      class: 'text-center fixed-column 100px l-600 ',
      cellClass: 'text-center fixed-column  100px l-600',
      colors: 'order_colors',
      comments: 'order_comments',
      hideEdit: true,
      editable: true,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      width: '100px',
      filter: { place_holder: '', type: 'number' },
      filters: [
        {
          text: 'Dossier',
          place_holder: 'Dossier',
          type: 'text',
          label: 'name',
          id: 'id',
          multiple: false,
          key: 'publication.id',
          column: 'publication.id',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'orders.publication_id',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'publication_id',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-publication.publication_id',
          column: 'publication_id',
        },
      ],
    },
    {
      text: 'ISBN',
      value: 'publication.isbn',
      align: 'center',
      class: 'text-center fixed-column 100px l-700 b-right',
      cellClass: 'text-center fixed-column  100px l-700  b-right',
      width: '100px',
      type: 'text',
      trueValue: 1,
      falseValue: 0,
      colors: 'order_colors',
      comments: 'order_comments',
      hideEdit: true,
      editable: true,
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: '', type: 'text',
      },
      filters: [
        {
          text: 'ISBN',
          place_holder: 'ISBN',
          type: 'text',
          values: store.state.app.printers,
          label: 'name',
          id: 'id',
          multiple: false,
          key: 'publications.isbn',
          columns: ['publications.isbn'],
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'orders.publication_id',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'publication.isbn',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-publication.isbn',
          column: 'publication.isbn',
        },
      ],
    },
    {
      text: 'Titre',
      value: 'publication.title',
      width: '150px',
      align: 'center',
      action: 'title',
      class: 'text-center pl-2',
      cellClass: 'text-center pl-2',
      viewPermission: 'title',
      editPermission: 'edit_title',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Titre" ?',
      fields: [
        // {
        //   type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        text: 'Titre',
        place_holder: 'Titre',
        type: 'text',
        label: 'name',
        id: 'id',
        multiple: false,

        key: 'publications.title',
        columns: ['publications.title'],
        join_table: 'publications',
        join_column: 'publications.id',
        local_column: 'orders.publication_id',
      },
      // filters: [
      //   {
      //     text: 'Titre',
      //     place_holder: 'Titre',
      //     type: 'text',
      //     label: 'name',
      //     id: 'id',
      //     multiple: false,
      //
      //     key: 'publications.title',
      //     columns: ['publications.title'],
      //     join_table: 'publications',
      //     join_column: 'publications.id',
      //     local_column: 'orders.publication_id',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'printer.name',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-printer_id',
      //     column: 'printer_id',
      //   },
      // ],
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      width: '150px',
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de \'Auteur\' ?',
      fields: [
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        place_holder: 'Auteur',
        type: 'text',
        key: 'authors.id',
        columns: ['authors.full_name'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'orders.author_id',
      },
      // filters: [
        // {
        //   place_holder: 'Auteur',
        //   type: 'text',
        //   key: 'authors.id',
        //   columns: ['authors.full_name'],
        //   join_table: 'authors',
        //   join_column: 'authors.id',
        //   local_column: 'orders.author_id',
        // },
        // {
        //   text: 'Couleur de fond',
        //   place_holder: 'Couleur de fond',
        //   type: 'select',
        //   values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        //   label: 'color_name',
        //   id: 'id',
        //   multiple: true,
        //   key: 'order_colors.column_name',
        //   join_table: 'order_colors',
        //   join_column: 'order_colors.order_id',
        //   local_column: 'orders.id',
        //   conditions: [
        //     {
        //       name: 'order_colors.column_name',
        //       value: 'email',
        //       single: true,
        //     },
        //     {
        //       name: 'order_colors.color_id',
        //       value: null,
        //       single: false,
        //     },
        //   ],
        // },
        // {
        //   text: 'Condition',
        //   place_holder: 'Condition',
        //   type: 'select',
        //   is_condition: true,
        //   values: [
        //     { id: 0, label: 'Est vide' },
        //     { id: 1, label: 'N\'est pas vide' },
        //   ],
        //   label: 'label',
        //   id: 'id',
        //   multiple: false,
        //   key: 'order-author_id',
        //   column: 'author_id',
        // },
      // ],
    },
    {
      text: 'Quantité',
      value: 'quantity',
      align: 'center',
      type: 'number',
      filter: { place_holder: '', type: 'text' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Quantité" ?',
      fields: [
        {
          type: 'number', label: 'Quantité', value: 'quantity', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'quantity',
          text: 'Quantité',
          place_holder: 'Quantité',
          type: 'select',
          multiple: true,
          values: [],
          id: 'id',
          label: 'label',
          column: 'quantity',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'quantity',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-quantity',
          column: 'quantity',
        },
      ],
    },
    {
      text: 'Destination',
      value: 'destination.name',
      column_name: 'destination_id',
      align: 'center',
      cellClass: 'text-center',
      action: 'destination.label',
      viewPermission: 'destination',
      editPermission: 'edit_destination',
      colors: 'order_colors',
      comments: 'order_comments',
      color_column: 'destination_id',
      comment_column: 'destination_id',
      editable: true,
      info: 'Confirmer le traitement de "Destination" ?',
      fields: [
        {
          type: 'select',
          label: 'Destination',
          value: 'destination_id',
          required: false,
          items: store.state.app.destinations,
          item_text: 'name',
          item_value: 'id',
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          text: 'Destination',
          place_holder: 'Destination',
          type: 'select',
          values: store.state.app.destinations,
          label: 'name',
          id: 'id',
          multiple: true,
          key: 'destinations.id',
          column: 'destinations.id',
          join_table: 'destinations',
          join_column: 'destinations.id',
          local_column: 'orders.destination_id',
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'destination_id',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-destination_id',
          column: 'destination_id',
        },
      ],
    },
    {
      text: 'Expédié',
      value: 'expedition_date',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { day: 'numeric', month: 'short' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      group: true,
      info: 'Confirmer le traitement de "Expédition" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'expedition_date', required: false, noLimit: true
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'expedition_date',
          text: 'Expédition',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'order_colors.column_name',
          join_table: 'order_colors',
          join_column: 'order_colors.order_id',
          local_column: 'orders.id',
          conditions: [
            {
              name: 'order_colors.column_name',
              value: 'expedition_date',
              single: true,
            },
            {
              name: 'order_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-expedition_date',
          column: 'expedition_date',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /*canEdit,*/
      ],
    },
    {
      text: 'Enseigne',
      value: 'observation',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Enseigne" ?',
      fields: [
        {
          type: 'text', label: 'Enseigne', value: 'observation', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'observation',
        text: 'Enseigne',
        place_holder: 'Enseigne',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'observation',
      //     text: 'Observation',
      //     place_holder: 'Observation',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'observation',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-observation',
      //     column: 'observation',
      //   },
      // ],
    },
    {
      text: 'Destinataire',
      value: 'destinataire',
      align: 'center',
      filter: { place_holder: '', type: 'text' },
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Destinataire" ?',
      fields: [
        {
          type: 'text', label: 'Destinataire', value: 'destinataire', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'destinataire',
        text: 'Destinataire',
        place_holder: 'Destinataire',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'observation',
      //     text: 'Observation',
      //     place_holder: 'Observation',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'observation',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-observation',
      //     column: 'observation',
      //   },
      // ],
    },
    {
      text: 'Adresse',
      value: 'address',
      align: 'center',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Adresse" ?',
      fields: [
        {
          type: 'text', label: 'Adresse', value: 'address', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'address',
        text: 'Adresse',
        place_holder: 'Adresse',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'address',
      //     text: 'Adresse',
      //     place_holder: 'Adresse',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'address',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-address',
      //     column: 'address',
      //   },
      // ],
    },
    {
      text: 'Cplt',
      value: 'address_line2',
      align: 'center',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Complément d’adresse" ?',
      fields: [
        {
          type: 'text', label: 'Complément d’adresse', value: 'address_line2', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'address_line2',
        text: 'Complément d’adresse',
        place_holder: 'Complément d’adresse',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'address_line2',
      //     text: 'Complément d’adresse',
      //     place_holder: 'Complément d’adresse',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'address_line2',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-address_line2',
      //     column: 'address_line2',
      //   },
      // ],
    },
    {
      text: 'Code postal',
      value: 'postcode',
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '100px',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Le code postal" ?',
      fields: [
        {
          type: 'text', label: 'Le code postal', value: 'postcode', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'postcode',
        text: 'Le code postal',
        place_holder: 'Code postal',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'postcode',
      //     text: 'Le code postal',
      //     place_holder: 'Complément d’adresse',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'postcode',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-postcode',
      //     column: 'postcode',
      //   },
      // ],
    },
    {
      text: 'Ville',
      value: 'city',
      align: 'center',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Ville" ?',
      fields: [
        {
          type: 'text', label: 'Ville', value: 'city', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'city',
        text: 'Ville',
        place_holder: 'Ville',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'city',
      //     text: 'Ville',
      //     place_holder: 'Ville',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'city',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-city',
      //     column: 'city',
      //   },
      // ],
    },
    {
      text: 'Pays',
      align: 'center',
      value: 'country.full_name',
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '75px',
      colors: 'order_colors',
      comments: 'order_comments',
      column_name: 'country_id',
      editable: true,
      info: 'Confirmer le traitement de "Destination" ?',
      fields: [
        {
          type: 'select',
          label: 'Pays',
          value: 'country_id',
          required: false,
          items: store.state.app.pays,
          item_text: 'full_name',
          item_value: 'id',
        },
        // {
        //   type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        column: 'country_id',
        text: 'Pays',
        place_holder: 'Pays',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        key: 'countries.id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'orders.country_id',
      },
      // filters: [
      //   {
      //     column: 'country_id',
      //     text: 'Pays',
      //     place_holder: 'Pays',
      //     type: 'select',
      //     values: store.state.app.pays,
      //     label: 'full_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'countries.id',
      //     join_table: 'countries',
      //     join_column: 'countries.id',
      //     local_column: 'orders.country_id',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'destination.name',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-country_id',
      //     column: 'country_id',
      //   },
      // ],

    },
    {
      text: 'Téléphone',
      value: 'phone',
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      colors: 'order_colors',
      comments: 'order_comments',
      editable: true,
      info: 'Confirmer le traitement de "Téléphone" ?',
      fields: [
        {
          type: 'text', label: 'Téléphone', value: 'phone', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'phone',
        text: 'Téléphone',
        place_holder: 'Téléphone',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'phone',
      //     text: 'Téléphone',
      //     place_holder: 'Téléphone',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'phone',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-phone',
      //     column: 'phone',
      //   },
      // ],
    },
    {
      text: 'Mail',
      value: 'email',
      fixed: true,
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '150px',
      colors: 'order_colors',
      comments: 'order_comments',
      type: 'text',
      editable: true,
      info: 'Confirmer le traitement de "Mail" ?',
      fields: [
        {
          type: 'combo', label: 'Mail', value: 'email', required: false,
        },
        // {
        //   type: 'color',
        //   label: 'Couleur de fond',
        //   value: 'color_id',
        //   item_value: 'id',
        //   item_text: 'color_name',
        //   items: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
        // },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filter: {
        key: 'email',
        text: 'Mail',
        place_holder: 'Mail',
        type: 'text',
      },
      // filters: [
      //   {
      //     key: 'email',
      //     text: 'Mail',
      //     place_holder: 'Mail',
      //     type: 'text',
      //   },
      //   {
      //     text: 'Couleur de fond',
      //     place_holder: 'Couleur de fond',
      //     type: 'select',
      //     values: store.state.app.colors.filter(el => store.state.app.settings.order_colors_ids.includes(el.id)),
      //     label: 'color_name',
      //     id: 'id',
      //     multiple: true,
      //     key: 'order_colors.column_name',
      //     join_table: 'order_colors',
      //     join_column: 'order_colors.order_id',
      //     local_column: 'orders.id',
      //     conditions: [
      //       {
      //         name: 'order_colors.column_name',
      //         value: 'email',
      //         single: true,
      //       },
      //       {
      //         name: 'order_colors.color_id',
      //         value: null,
      //         single: false,
      //       },
      //     ],
      //   },
      //   {
      //     text: 'Condition',
      //     place_holder: 'Condition',
      //     type: 'select',
      //     is_condition: true,
      //     values: [
      //       { id: 0, label: 'Est vide' },
      //       { id: 1, label: 'N\'est pas vide' },
      //     ],
      //     label: 'label',
      //     id: 'id',
      //     multiple: false,
      //     key: 'order-email',
      //     column: 'email',
      //   },
      // ],
      // filter: {
      //   place_holder: '',
      //   type: 'text',
      //   column: 'authors.email',
      //   join_table: 'authors',
      //   join_column: 'authors.id',
      //   local_column: 'orders.author_id',
      // },
    },
    {
      text: 'Latitude',
      value: 'latitude',
      fixed: true,
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '150px',
      colors: 'order_colors',
      comments: 'order_comments',
      type: 'text',
      editable: false,
      filter: {
        key: 'latitude',
        text: 'Latitude',
        place_holder: 'Latitude',
        type: 'text',
      },
      filters: [
        {
          key: 'latitude',
          text: 'Latitude',
          place_holder: 'Latitude',
          type: 'text',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-latitude',
          column: 'latitude',
        },
      ],
    },
    {
      text: 'Longitude',
      value: 'longitude',
      fixed: true,
      align: 'center',
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '150px',
      colors: 'order_colors',
      comments: 'order_comments',
      type: 'text',
      editable: false,
      filter: {
        key: 'longitude',
        text: 'Longitude',
        place_holder: 'Longitude',
        type: 'text',
      },
      filters: [
        {
          key: 'longitude',
          text: 'Longitude',
          place_holder: 'Longitude',
          type: 'text',
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'order-longitude',
          column: 'longitude',
        },
      ],
    },
    ...tableCommonColumns,
  ]
  const tableColumnsAuthorView = [
    {
      align: 'center',
      text: 'No',
      value: 'id',
      fixed: true,
      class: 'fixed-column 50px',
      cellClass: 'fixed-column 50px py-1',
      width: '50px',
      filter: { place_holder: '', type: 'text' },
    },
    {
      align: 'center',
      text: 'Commande',
      value: 'order_reference',
      fixed: true,
      class: 'fixed-column 50px l-50',
      cellClass: 'fixed-column 50px l-50 ',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'text',
      },
    },
    {
      text: 'Mail',
      value: 'author.email',
      fixed: true,
      class: 'fixed-column 150px l-100',
      cellClass: 'fixed-column 150px l-100',
      width: '150px',
      filter: {
        place_holder: '',
        type: 'text',
        column: 'authors.email',
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'orders.author_id',
      },
    },
    {
      align: 'center',
      text: 'Pays',
      value: 'author.country.full_name',
      class: 'fixed-column l-250 b-right 50px',
      cellClass: 'fixed-column l-250 b-right 50px',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        values: store.state.app.pays,
        label: 'full_name',
        id: 'id',
        multiple: true,
        column: 'authors.country_id',
        join_table: 'countries',
        join_column: 'countries.id',
        local_column: 'authors.country_id',
      },
    },

    ...tableCommonColumns,
  ]


  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isPropositionActive = ref(false)
  const isRelanceActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const accordDialog = ref(false)
  const downloadDialog = ref(false)
  const downloadObject = ref({ loading: false, url: '' })
  const searchQuery = ref('')
  const authorFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)
  const filters = ref([])
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const orderStates = ref({
    CREATED: 0,
    APPROVED: 1,
    SIGNED: 2,
    COLLECTED: 3,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }
  const select = data => {
    if (data.state) {
      selectedRows.value.push(data.item)
    } else {
      selectedRows.value.splice(selectedRows.value.findIndex(ele => ele.id === data.item.id), 1)
    }
  }

  /* filters.value = authorFilter.value != null ? tableColumnsAuthorView : tableColumns */

  // new item
  const openDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }
  const openPropositionDialog = () => {
    isPropositionActive.value = !isPropositionActive.value
  }
  const openRelanceDialog = () => {
    isRelanceActive.value = !isRelanceActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.publication && val.publication.state >= store.state.app.states.RESILIATION_STATE) {
      return false
    }
    item.value = val
    accordDialog.value = !accordDialog.value
  }

  // fetch data
  const fetchItems = download => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isPropositionActive.value = false
    isRelanceActive.value = false
    downloadObject.value = { loading: true, url: null }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    const columns = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.')).map(ele => ele.value)
    columns.push('author_id')
    columns.push('genre_id')
    columns.push('origin_id')
    columns.push('format_id')
    columns.push('source_id')
    columns.push('status_id')

    store
      .dispatch('app-order/fetchItems', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0))
          .map(ele => ({ ...ele.filter, key: ele.value, values: null })), */
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        pay: payFilter.value,
        author: authorFilter.value,
        columns,
        download,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data.map(ele => {
            ele.custom_discount = ele.custom_discount || false
            ele.order_date1 = ele.order_date || null

            return ele
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-order/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          loading.value = false
        })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  const saved = data => {
    items.value[data.index] = data.item
  }

  return {
    saved,
    orderStates,
    item,
    signatureForm,
    items,
    downloadDialog,
    downloadObject,
    filters,
    tableColumns: authorFilter.value != null ? tableColumnsAuthorView : tableColumns,
    searchQuery,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    select,
    isFormActive,
    isPropositionActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    signatureDialog,
    accordDialog,
    isRelanceActive,
    openRelanceDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openPropositionDialog,
    deleteItem,
    deleteDialog,
    confirmDelete,
    fetchItems,
  }
}
