import { ref, watch } from '@vue/composition-api'
import store from '@/store'

export default function controller() {
  const blankItem = {
    id: null,
    page_min: null,
    page_max: null,
    frais: null,
    subtraction: null,
    percentage: null,
    task_date: null,
    type: null,
    assistant_id: null,
    publication_id: null,
  }
  const form = ref(null)
  const roles = ref([])
  const items = ref([])
  const item = ref({})
  const filters = ref([
    {
      align: 'center',
      text: 'Dossier',
      value: 'publication_id',
      fixed: true,
      class: 'fixed-column 100px ',
      cellClass: 'fixed-column 100px py-1',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      align: 'center',
      text: 'Manuscrit',
      value: 'manuscript_id',
      fixed: true,
      class: 'fixed-column 100px l-100',
      cellClass: 'fixed-column 100px l-100  py-1',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'text' },
    },
    {
      text: 'Auteur',
      value: 'author.full_name',
      fixed: true,
      class: 'fixed-column l-200 b-right ',
      cellClass: 'fixed-column 300px l-200 b-right  py-1',
      width: '150px',
      filter: {
        place_holder: 'Nom...',
        type: 'text',
        columns: ['authors.full_name', 'authors.email'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'tasks.author_id',
      },
    },
    {
      text: 'Utilisateur',
      value: 'assistant.code',
      align: 'center',
      filter: {
        place_holder: 'CODE',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'users.id',
        join_table: 'users',
        join_column: 'users.id',
        local_column: 'tasks.assistant_id',
      },
    },
    {
      text: 'Type',
      value: 'type',
      align: 'center',
      filter: {
        place_holder: 'CODE',
        type: 'select',
        values: [
          { id: 'text', label: 'Traitement Texte' },
          { id: 'cover', label: 'Traitement couverture' },
          { id: 'publication', label: 'Parutions' },
          { id: 'communication', label: 'Communication' },
        ],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'tasks.type',
      },
    },
    {
      text: 'Période de traitement',
      value: 'task_date',
      align: 'center',
      cellClass: 'text-center',
      filters: [
        {
          key: 'task_date', column: 'task_date', text: 'Période', place_holder: 'Date1 - Date2', type: 'date', month: true, menu: false, range: true, noLimit: true,
        },
      ],
    },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ])
  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const deleteDialog = ref(false)
  const searchQuery = ref('')
  const totalItems = ref(0)
  const loading = ref(false)
  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [false],
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const loadedManuscripts = ref([])
  const loadedRoles = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val) {
      item.value = val
    } else {
      item.value = JSON.parse(JSON.stringify(blankItem))
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  // fetch data
  const fetchItems = () => {
    isFormActive.value = false

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    store
      .dispatch('task/fetchItems', {
        /* filters: filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({ ...ele.filter, key: ele.value, values: null })), */
        filters: [...singleFilters, ...selectedMultipleFilters],
        term: searchQuery.value,
        orderBy: options.value.sortBy ? (options.value.sortBy[0] || 'publication_id') : 'publication_id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'ASC' : 'DESC') : 'DESC',
        page: options.value.page,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        const { data, total } = response.data

        items.value = data
        totalItems.value = total

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('task/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
          loading.value = false
        })
    }
  }
  const fetchRoles = () => {
    store.dispatch('task/fetchRoles', {})
      .then(response => {
        roles.value = response.data
      }).catch(error => {

      })
  }

  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      loading.value = true
      selectedRows.value = []
      fetchItems()
    }, 500)
  }, { deep: true })

  watch([searchQuery, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  return {
    item,
    items,
    roles,
    filters,
    searchQuery,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    deleteDialog,
    loadedManuscripts,
    resetForm,
    openDialog,
    confirmDelete,
    deleteItem,
    fetchItems,
    fetchRoles,
  }
}
