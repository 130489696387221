import { onUnmounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import store from '@/store'
import router from '@/router'
import itemStoreModule from '@/views/apps/publications/itemStoreModule'
import ability from '@/plugins/acl/ability'

export default function controller() {
  const USER_APP_STORE_MODULE_NAME = 'app-publication'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, itemStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  const blankItem = {
    id: null,
    title: null,
    sum_words: null,
    sum_pages: null,
    custom_pages: false,
    custom_price: false,
    public_price: null,
    sum_copies: store.state.app.settings.dafault_value_copies,
    discount_price: null,
    unit_price: null,
    transportation: null,
    receipt_date: null,
    response_date: null,
    proposition_date: null,
    source_id: null,
    author_id: null,
    genre_id: null,
    format_id: null,
    country_id: null,
    author: {
      id: null,
      ful_name: null,
      email: null,
      author_ref: '',
      phone: null,
      avatar: null,
      source_id: null,
      status_id: null,
      user_id: null,
      addresses: [],
      password: null,
      changePassword: true,
    },
  }
  const form = ref()
  const items = ref([])
  const item = ref({})
  const paymentMethods = ref([
    { id: 'CB CPT', name: 'CB CPT' },
    { id: 'CB 2', name: 'CB 2' },
    { id: 'CB 3', name: 'CB 3' },
    { id: 'PP CPT', name: 'PP CPT' },
    { id: 'PP 2', name: 'PP 2' },
    { id: 'PP 3', name: 'PP 3' },
    { id: 'PP 4', name: 'PP 4' },
    { id: 'PP 5', name: 'PP 5' },
    { id: 'PP 6', name: 'PP 6' },
    { id: 'PP 7', name: 'PP 7' },
    { id: 'PP 8', name: 'PP 8' },
    { id: 'PP 9', name: 'PP 9' },
    { id: 'PP 10', name: 'PP 10' },
    { id: 'PP 11', name: 'PP 11' },
    { id: 'PP 12', name: 'PP 12' },
    { id: 'PP 13', name: 'PP 13' },
    { id: 'PP 14', name: 'PP 14' },
    { id: 'PP 15', name: 'PP 15' },
    { id: 'VT CPT', name: 'VT CPT' },
    { id: 'VT 2', name: 'VT 2' },
    { id: 'VT 3', name: 'VT 3' },
    { id: 'VT 4', name: 'VT 4' },
    { id: 'VT 5', name: 'VT 5' },
    { id: 'WU CPT', name: 'WU CPT' },
    { id: 'WU 2', name: 'WU 2' },
    { id: 'WU 3', name: 'WU 3' },
    { id: 'CP CPT', name: 'CP CPT' },
    { id: 'CP 2', name: 'CP 2' },
    { id: 'CP 3', name: 'CP 3' },
    { id: 'null', name: 'Vide' },
  ])
  const blockeds = ref([
    { id: 'DD', label: 'Dossier décalé' },
    { id: 'Pmt', label: 'Paiement' },
    { id: 'NS', label: 'Non signé' },
    { id: 'FW', label: 'Fichier Word' },
    { id: 'BC', label: 'BC' },
    { id: null, label: 'Ouvert' },
  ])
  const qualities = ref(
    [
      { id: 3, label: 'TQS ', title: 'TQS - Texte de Qualité Supérieur' },
      { id: 1, label: 'TQ', title: 'TQ - Texte de Qualité' },
      { id: 2, label: 'TM', title: 'TM - Texte Moyen' },
      { id: 0, label: 'TS', title: 'TS - Texte Simple' },
      { id: -1, label: 'TSP', title: 'TSP - Texte Simple Plus' },
    ],
  )
  const signatureForm = ref({})
  const canEdit = (th, td) => {
    if (td && th.value && td.publication && td.publication.is_quality_text === 0 && td.is_open_to_full_edit === 0) {
      td.denieds = td.denieds || {}
      td.denieds[th.value] = true
      td.classes = td.classes || {}
      td.classes[th.value] = { 'w-100': true, 'h-100': true, 'add-padding': true }
      td.classes[th.value][th.blockBg || 'bg-grey'] = true
    } else {

    }
  }
  const tableExtraColumns = [
    /* {
      align: 'center',
      text: '',
      value: 'is_reading',
      fixed: true,
      class: 'fixed-column 50px ',
      cellClass: 'fixed-column 50px ',
      width: '50px',
      filter: {
        place_holder: '',
        type: 'select',
        id: 'id',
        label: 'name',
        multiple: false,
        column: 'publications.is_reading',
        values: [
          { id: 1, name: '*' },
        ],
      },
    }, */
    {
      text: 'N° Dossier',
      align: 'center',
      value: 'publication_id',
      fixed: true,
      class: 'fixed-column 100px',
      cellClass: 'fixed-column 100px py-1',
      comment_column: 'id',
      comment_reference: 'type',
      comments: 'publication.publication_records',
      width: '100px',
      filter: { place_holder: 'Numero...', type: 'number' },
    },
    {
      text: 'Auteur',
      align: 'center',
      value: 'publication.author.full_name',
      fixed: true,
      class: 'fixed-column 50px l-100',
      cellClass: 'fixed-column  l-100  py-1',
      width: '150px',
      filter: {
        place_holder: 'Nom...',
        type: 'text',
        columns: ['authors.full_name', 'authors.email'],
        join_table: 'authors',
        join_column: 'authors.id',
        local_column: 'publications.author_id',
      },
    },
    {
      text: 'Assistant conception',
      value: 'publication.assistant.code',
      fixed: true,
      class: 'fixed-column l-250  ',
      cellClass: 'fixed-column 50px l-250  py-1',
      width: '100px',
      sortable: false,
      align: 'center',
      filter: {
        place_holder: 'Code',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'publications.text_assistant',
        join_table: 'users',
        join_column: 'users.id',
        local_column: 'publications.text_assistant',
      },
    },
    {
      text: 'Période de traitement',
      value: 'periode_de_traitement',
      fixed: true,
      class: 'text-center fixed-column  l-350 b-right',
      cellClass: 'text-center fixed-column l-350 b-right',
      date_format: { month: 'short', year: 'numeric' },
      type: 'date',
      align: 'center',
      action: 'periode_de_traitement',
      editable: true,
      colors: 'communication_colors',
      comments: 'communication_comments',
      info: 'Confirmer le traitement de "Période de traitement" ?',
      fields: [
        {
          type: 'date', label: 'Période de traitement', value: 'periode_de_traitement', required: false, noLimit: true,
        },
        /*{
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },*/
      ],
      filters: [
        {
          key: 'periode_de_traitement',
          text: 'Date de fin de conception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communications_periode_de_traitement',
          column: 'periode_de_traitement',
        },
      ],
    },
    {
      text: 'Paiement auteur',
      value: 'publication.author_payment',
      align: 'center',
      url: 'paiement_author_payment',
      // color_column: 'author_payment',
      comment_column: 'paiement_author_payment',
      blockBg: 'bg-grey',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      // colors: 'publication.publication_colors',
      // comments: 'publication.publication_records',
      // color_reference: 'colomn_name',
      // comment_reference: 'type',
      editable: false,
      filters: [
        {
          key: 'publications.author_payment',
          column: 'publications.author_payment',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Paiement auteur',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },

        // {
        //   text: 'Couleur de fond',
        //   place_holder: 'Couleur de fond',
        //   type: 'select',
        //   values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
        //   label: 'color_name',
        //   id: 'id',
        //   multiple: true,
        //   key: 'publication_colors.colomn_name',
        //
        //   join_table: 'publication_colors',
        //   join_column: 'publication_colors.publication_id',
        //   local_column: 'publications.id',
        //   conditions: [
        //     {
        //       name: 'publication_colors.colomn_name',
        //       value: 'llb_website',
        //       single: true,
        //     },
        //     {
        //       name: 'publication_colors.color_id',
        //       value: null,
        //       single: false,
        //     },
        //   ],
        // },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_llb_website',
          column: 'publications.llb_website',
        },
      ],
    },
    {
      text: 'Cas particulier',
      value: 'publication.manuscript.particular_case.label',
      align: 'center',
      action: 'publication.manuscript.particular_case.label',
      filter: {
        place_holder: '',
        type: 'select',

        values: store.getters['particularCase/particular_cases'],
        //values: [{ id: 'yes', label: 'Oui' }, { id: 'no', label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'manuscripts.particular_case_id',
        join_table: 'manuscripts',
        join_column: 'manuscripts.id',
        local_column: 'publications.manuscript_id',
      },
    },

    {
      text: 'Accord de publication',
      value: 'publication.completion_date',
      type: 'date',
      date_format: { month: 'short', year: 'numeric' },
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '110px',
      align: 'center',
      filters: [
        {
          key: 'publications.completion_date',
          column: 'publications.completion_date',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Date de fin de conception',
          place_holder: 'Date1 - Date2',
          type: 'date',
          month: true,
          menu: false,
          range: true,
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_completion_date',
          column: 'publications.completion_date',
        },
      ],
    },
    {
      text: 'ISBN',
      value: 'publication.isbn',
      class: 'text-center  ',
      cellClass: 'text-center ',
      width: '100px',
      align: 'center',

      filter: {
        key: 'publications.isbn',
        place_holder: 'ISBN...',
        type: 'text',
        column: 'publications.isbn',
        join_table: 'publications',
        join_column: 'publications.id',
        local_column: 'communications.publication_id',
      },

    },
    {
      text: 'Titre',
      value: 'publication.title',
      class: 'text-center',
      cellClass: 'text-center ',
      width: '300px',
      align: 'center',
      filter: {
        place_holder: 'Titre',
        type: 'text',
        columns: ['publications.title'],
        join_table: 'publications',
        join_column: 'publications.id',
        local_column: 'publication_id',
      },
    },
    {
      text: 'Genre',
      value: 'publication.genre.label',
      class: 'text-center  ',
      cellClass: 'text-center ',
      width: '100px',
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'label',
        id: 'id',
        multiple: true,
        column: 'publications.genre_id',
        join_table: 'genres',
        join_column: 'genres.id',
        local_column: 'publications.genre_id',
      },
    },
    {
      text: 'Qualité',
      value: 'is_quality_text',
      align: 'center',
      cellClass: 'text-center',
      action: 'cp',
      type: 'select',
      selected_value: 'is_quality_text',
      select_label: 'label',
      items: qualities.value,
      column_name: 'is_quality_text',
      colors: 'communication_colors',
      color_column: 'is_quality_text',
      comments: 'communication_comments',
      comment_column: 'is_quality_text',
      editable: true,
      hideEdit: false,
      info: 'Confirmer le changement de couleur de "Qualité" ?',
      fields: [
        {
          type: 'select',
          label: 'Qualité',
          value: 'is_quality_text',
          required: false,
          items: qualities.value,
          item_text: 'title',
          item_value: 'id',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          column: 'is_quality_text',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          text: 'Qualité du texte',
          column: 'communications.is_quality_text',
          key: 'is_quality_text',
          place_holder: 'Qualité du texte',
          type: 'select',
          values: [...qualities.value, {
            id: 'Null',
            label: 'Vide',
            title: 'Vide',
          }],
          label: 'title',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'is_quality_text',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'is_quality_text',
          column: 'publications.is_quality_text',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        (th, td) => {

        },
      ],
    },
    {
      text: 'Assistant communication',
      value: 'assistant.code',
      fixed: true,
      class: 'text-center ',
      cellClass: 'text-center ',
      width: '100px',
      sortable: true,
      align: 'center',
      filter: {
        place_holder: '',
        type: 'select',
        values: [],
        label: 'code',
        id: 'id',
        multiple: true,
        column: 'publications.text_assistant',
        join_table: 'publications',
        join_column: 'publications.id',
        local_column: 'communications.publication_id',
      },
    },
    {
      text: 'Publication Site LLB',
      value: 'publication.llb_website',
      comment_column: 'publication_llb_website',
      color_column: 'llb_website',
      align: 'center',
      action: 'site',
      type: 'date',
      colors: 'publication.publication_colors',
      comments: 'publication.publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      filters: [
        {
          key: 'publications.llb_website',
          column: 'publications.llb_website',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Publication Site LLB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'llb_website',
              single: true,
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_llb_website',
          column: 'publications.llb_website',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Cas spécial',
      value: 'is_open_to_full_edit',
      align: 'center',
      cellClass: 'text-center',
      action: 'cp',
      type: 'select',
      select_label: 'label',
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      editable: true,

      filter: {
        column: 'is_open_to_full_edit',
        place_holder: 'Autorisation spéciale',
        type: 'select',
        values: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
        label: 'label',
        id: 'id',
        multiple: false,
      },
      info: 'Changement de l\'autorisation spéciale',
      fields: [
        {
          type: 'select',
          items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
          item_text: 'label',
          item_value: 'id',
          value: 'is_open_to_full_edit',
        },
      ],
    },
    {
      text: 'Présence réseaux sociaux',
      value: 'publication.compte_facebook',
      comment_column: 'publication_compte_facebook',
      color_column: 'compte_facebook',
      align: 'center',
      action: 'site',
      type: 'date',
      colors: 'publication.publication_colors',
      comments: 'publication.publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      filters: [
        {
          key: 'publications.compte_facebook',
          column: 'publications.compte_facebook',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Présence réseaux sociaux',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'publication_colors.colomn_name',

          join_table: 'publication_colors',
          join_column: 'publication_colors.publication_id',
          local_column: 'publications.id',
          conditions: [
            {
              name: 'publication_colors.colomn_name',
              value: 'compte_facebook',
              single: true,
              join_table: 'publication_colors',
              local_table: 'publications',
              join_column: 'publication_id',
              local_column: 'id',
            },
            {
              name: 'publication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_compte_facebook',
          column: 'publications.compte_facebook',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Facebook',
      value: 'publication.facebook',
      comment_column: 'facebook',
      color_column: 'facebook',
      align: 'center',
      action: 'site',
      select_label: 'label',
      type: 'select',
      colors: 'publication.publication_colors',
      comments: 'publication.publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      filters: [
        {
          key: 'publications.facebook',
          column: 'publications.facebook',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Facebook',
          place_holder: 'Facebook',
          type: 'select',
          values: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
          label: 'label',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_facebook',
          column: 'publications.facebook',
        },
      ],
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Instagram',
      value: 'publication.instagram',
      comment_column: 'instagram',
      color_column: 'instagram',
      align: 'center',
      action: 'site',
      select_label: 'label',
      type: 'select',
      colors: 'publication.publication_colors',
      comments: 'publication.publication_records',
      color_reference: 'colomn_name',
      comment_reference: 'type',
      editable: false,
      hideValue: false,
      items: [{ id: 1, label: 'Oui' }, { id: 0, label: 'Non' }],
      filters: [
        {
          key: 'publications.instagram',
          column: 'publications.instagram',
          join_table: 'publications',
          join_column: 'publications.id',
          local_column: 'communications.publication_id',
          text: 'Instagram',
          place_holder: 'Instagram',
          type: 'select',
          values: [{id: 1, label: 'Oui'}, {id: 0, label: 'Non'}],
          label: 'label',
          id: 'id',
          multiple: false,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_instagram',
          column: 'publications.instagram',
        },
      ],
      functions: [
        canEdit,
      ],
    },
  ]
  const tableCommonColumns = [
    ...tableExtraColumns,

    /* {
      text: 'Accord de publication',
      value: 'publishing_period',
      align: 'center',
      cellClass: 'text-center',
      filter: { place_holder: '', type: 'text' },
      filters: [
        {
          key: 'publishing_period', text: 'Accord de publication', place_holder: 'Date1 - Date2', type: 'date', month: true, menu: false, range: true, column: 'completion_date',noLimit:true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'publication_publishing_period',
          column: 'publishing_period',
        },
      ],
    },
    {
      text: 'ISBN',
      value: 'publication.isbn',
      align: 'center',
      cellClass: 'text-center',
      filter: { place_holder: '', type: 'text' },
    },
    {
      text: 'Genre',
      value: 'publication.genre.title',
      align: 'center',
      cellClass: 'text-center',
      width: '200px',
      filter: {
        place_holder: '',
        type: 'text',
        column: 'genres.id',
        join_table: 'genres',
        join_column: 'genres.id',
        local_column: 'publications.genre_id',
      },
    }, */
  ]
  const defaultColumn = {
    text: 'Date signature',
    value: 'signature_date',
    align: 'center',
    cellClass: 'text-center',
    action: 'edit',
    type: 'date',
    date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
    colors: 'publication_colors',
    comments: 'publication_records',
    color_column: 'signature_date', // Name of the column_to look for in the data
    color_reference: 'colomn_name', // Field identifying the column name
    comment_column: 'signature_date', // Name of the column_to look for in the data
    comment_reference: 'type', // Field identifying the column name
    url: 'signature_date', // Field identifying the column name
    editable: true,
    info: 'Confirmer-vous de vouloir modifie la date?',
    fields: [
      {
        type: 'date', label: 'Date de traitement', value: 'signature_date', required: false,
      },
      {
        type: 'color',
        label: 'Couleur de fond',
        value: 'color_id',
        item_value: 'id',
        item_text: 'color_name',
        items: store.state.app.colors,
      },
      { type: 'textarea', label: 'Commentaire', value: 'comment' },
    ],
    filter: {
      place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
    },
    filters: [
      {
        key: 'signature_date',
        text: 'Date signature',
        place_holder: 'Date1 - Date2',
        type: 'date',
        menu: false,
        range: true,
      },
      {
        text: 'Couleur de fond',
        place_holder: 'Couleur de fond',
        type: 'select',
        values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
        label: 'color_name',
        id: 'id',
        multiple: true,
        key: 'publication_colors.colomn_name',

        /* columns: 'publication_colors.color_id', */
        join_table: 'publication_colors',
        join_column: 'publication_colors.publication_id',
        local_column: 'publications.id',
        conditions: [
          {
            name: 'publication_colors.colomn_name',
            value: 'signature_date',
            single: true,
          },
          {
            name: 'publication_colors.color_id',
            value: null,
            single: false,
          },
        ],
      },
      {
        text: 'Condition',
        place_holder: 'Condition',
        type: 'select',
        is_condition: true,
        values: [
          { id: 0, label: 'Est vide' },
          { id: 1, label: 'N\'est pas vide' },
        ],
        label: 'label',
        id: 'id',
        multiple: false,
        key: 'publication_signature_date',
        column: 'signature_date',
      },
    ],
  }
  const communicationTableColumnsForReport = [
    /* {
      text: 'Mail avis auteur',
      value: 'mail_avis_auteur',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Mail avis auteur" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'mail_avis_auteur', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'mail_avis_auteur', text: 'Mail avis auteur', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'mail_avis_auteur',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication_mail_avis_auteur',
          column: 'mail_avis_auteur',
        },
      ],
    }, */
    {
      text: 'Annonce parution',
      value: 'annonce_parution',
      align: 'center',
      cellClass: 'text-center',
      action: 'annonce_parution',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer vous que l\'annonce parution ai été faite ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'annonce_parution', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'annonce_parution',
          text: 'Dates de l\'annonce parrution',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'annonce_parution',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-annonce_parution',
          column: 'annonce_parution',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /* canEdit, */
      ],
    },
    {
      text: 'Prise de contact',
      value: 'prise_contact_communication',
      align: 'center',
      cellClass: 'text-center',
      action: 'prise_contact_communication',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer vous que la prise de contact a été faite ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'prise_contact_communication', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'prise_contact_communication',
          text: 'Dates de la prise de contact',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'prise_contact_communication',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-prise_contact_communication',
          column: 'prise_contact_communication',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /* canEdit, */
      ],
    },

    //Images/Elements de base
    {
      text: 'CP',
      value: 'cp',
      align: 'center',
      cellClass: 'text-center',
      action: 'cp',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "CP" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'cp', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'cp',
          text: 'CP',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'cp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-cp',
          column: 'cp',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /* canEdit, */
      ],
    },
    {
      text: 'Affiche',
      value: 'affiche_standard',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Affiche" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'affiche_standard', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'affiche_standard',
          text: 'Affiche',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'affiche_standard',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-affiche_standard',
          column: 'affiche_standard',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /* canEdit, */
      ],
    },
    {
      text: 'Couv FB',
      value: 'couverture_fb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Couverture Facebook" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'couverture_fb', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'couverture_fb',
          text: 'Couv Facebook',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'couverture_fb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-couverture_fb',
          column: 'couverture_fb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        /* canEdit, */
      ],
    },
    {
      text: 'DP',
      value: 'dp',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "DP" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'dp', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors,
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'dp',
          text: 'DP',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'dp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-dp',
          column: 'dp',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Autres Visuels',
      value: 'autres_visuels',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Autres Visuels" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'autres_visuels', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'autres_visuels',
          text: 'Autres Visuels',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'autres_visuels',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-autres_visuels',
          column: 'autres_visuels',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Photofunia',
      value: 'visuel_photofunia',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Photofunia" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'visuel_photofunia', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'visuel_photofunia',
          text: 'Photofunia',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'visuel_photofunia',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-visuel_photofunia',
          column: 'visuel_photofunia',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Audio
    {
      text: 'Enregistrement audio',
      value: 'enregistrement_audio',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Enregistrement audio" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'enregistrement_audio', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'enregistrement_audio',
          text: 'Enregistrement audio',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'enregistrement_audio',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-enregistrement_audio',
          column: 'enregistrement_audio',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Vidéos
    {
      text: 'Storie FB',
      value: 'storie_fb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Storie FB" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'storie_fb', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'storie_fb',
          text: 'Storie FB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'storie_fb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-storie_fb',
          column: 'storie_fb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Storie Instagram',
      value: 'storie_insta',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Storie Instagram" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'storie_insta', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'storie_insta',
          text: 'Storie Instagram',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'storie_insta',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-storie_insta',
          column: 'storie_insta',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Booktrailer',
      value: 'booktrailer',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Booktrailer" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'booktrailer', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'booktrailer',
          text: 'Booktrailer',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'booktrailer',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-booktrailer',
          column: 'booktrailer',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Community manager
    {
      text: 'Publication Insta',
      value: 'publication_insta',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication Insta" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'publication_insta', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_insta',
          text: 'Publication Insta',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'publication_insta',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_insta',
          column: 'publication_insta',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Publication BT-YtB',
      value: 'publication_ytb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication BT-YtB" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'publication_ytb', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_ytb',
          text: 'Publication BT-YtB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'publication_ytb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_ytb',
          column: 'publication_ytb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Publication BT-FB',
      value: 'publication_bt_fb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication BT-FB" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'publication_bt_fb', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_bt_fb',
          text: 'Publication BT-FB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'publication_bt_fb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_bt_fb',
          column: 'publication_bt_fb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Publication BT-Viméo',
      value: 'publication_video_vimeo',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication BT-Viméo" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'publication_video_vimeo',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_video_vimeo',
          text: 'Publication BT-Viméo',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'publication_video_vimeo',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_video_vimeo',
          column: 'publication_video_vimeo',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Partage FB',
      value: 'partage_fb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Partage FB" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'partage_fb', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'partage_fb',
          text: 'Partage FB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'partage_fb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-partage_fb',
          column: 'partage_fb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Partage Insta',
      value: 'partage_insta',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Partage Insta" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'partage_insta', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'partage_insta',
          text: 'Partage Insta',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'partage_insta',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-partage_insta',
          column: 'partage_insta',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //chroniqueurs
    {
      text: 'DP Instagrameuses',
      value: 'dp_a_7_instagrameuses',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "DP Instagrameuses" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'dp_a_7_instagrameuses',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'dp_a_7_instagrameuses',
          text: 'DP Instagrameuses',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'dp_a_7_instagrameuses',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-dp_a_7_instagrameuses',
          column: 'dp_a_7_instagrameuses',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Simplement Pro',
      value: 'simplement_pro',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Simplement Pro" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'simplement_pro', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'simplement_pro',
          text: 'Simplement Pro',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'simplement_pro',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-simplement_pro',
          column: 'simplement_pro',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'SP Babelio',
      value: 'sp_babelio',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "SP Babelio" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'sp_babelio', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'sp_babelio',
          text: 'SP Babelio',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'sp_babelio',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-sp_babelio',
          column: 'sp_babelio',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Salons
    {
      text: 'Salons',
      value: 'actions_promo',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Salons" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'actions_promo', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'actions_promo',
          text: 'Salons',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'actions_promo',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-actions_promo',
          column: 'actions_promo',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'SP broché',
      value: 'sp_broche_4',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de SP broché ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'sp_broche_4', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'sp_broche_4',
          text: 'SP broché',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'sp_broche_4',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-sp_broche_4',
          column: 'sp_broche_4',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'SP numérique',
      value: 'sp_numerique_6',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de SP numérique ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'sp_numerique_6', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'sp_numerique_6',
          text: 'SP numérique',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'sp_numerique_6',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-sp_numerique_6',
          column: 'sp_numerique_6',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Librairie/PDV
    {
      text: 'Envoi DP-Libraires',
      value: 'envoi_dp_a_libraire',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Envoi DP-Libraires" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'envoi_dp_a_libraire', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'envoi_dp_a_libraire',
          text: 'Envoi DP-Libraires',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'envoi_dp_a_libraire',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-envoi_dp_a_libraire',
          column: 'envoi_dp_a_libraire',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Envoi DP-Dédi',
      value: 'envoi_dp_pour_dedi',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Envoi DP-Dédi" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'envoi_dp_pour_dedi', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'envoi_dp_pour_dedi',
          text: 'Envoi DP-Dédi',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'envoi_dp_pour_dedi',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-envoi_dp_pour_dedi',
          column: 'envoi_dp_pour_dedi',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Médias
    {
      text: 'DP presse',
      value: 'dp_presse',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "DP presse" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'dp_presse', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'dp_presse',
          text: 'DP presse',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'dp_presse',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-dp_presse',
          column: 'dp_presse',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'DP Radio',
      value: 'dp_radio',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "DP Radio" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'dp_radio', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'dp_radio',
          text: 'DP Radio',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'dp_radio',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-dp_radio',
          column: 'dp_radio',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'DP TV',
      value: 'dp_tv',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "DP TV" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'dp_tv', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'dp_tv',
          text: 'DP TV',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'dp_tv',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-dp_tv',
          column: 'dp_tv',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'ITW',
      value: 'itw_p_demeure',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "ITW" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'itw_p_demeure', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'itw_p_demeure',
          text: 'ITW',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'itw_p_demeure',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-itw_p_demeure',
          column: 'itw_p_demeure',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Envoi ITW-Radio',
      value: 'envoi_a_radio',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Envoi ITW-Radio" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'envoi_a_radio', required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'envoi_a_radio',
          text: 'Envoi ITW-Radio',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'envoi_a_radio',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-envoi_a_radio',
          column: 'envoi_a_radio',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Envoi ITW-Presse',
      value: 'envoi_a_presse_locale',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Envoi ITW-Presse" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'envoi_a_presse_locale',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'envoi_a_presse_locale',
          text: 'Envoi ITW-Presse',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'envoi_a_presse_locale',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-envoi_a_presse_locale',
          column: 'envoi_a_presse_locale',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },

    //Prix
    {
      text: 'Prix littéraire',
      value: 'prix_litteraire',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Prix littéraire" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'prix_litteraire',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'prix_litteraire',
          text: 'Prix littéraire',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'prix_litteraire',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-prix_litteraire',
          column: 'envoi_a_presse_locale',
        },
      ],
      functions: [
        canEdit,
      ],
    },

    /*Compteur d'actions*/
    {
      text: 'Compteur d\'actions',
      align: 'center',
      value: 'compteur_actions',
      width: '100px',
      class: 'text-center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'number',
      editable: false,
      /*colors: 'communication_colors',
      comments: 'communication_comments',*/
      info: 'Changement de Compteur d\'actions',
      fields: [
        {
          type: 'number',
          label: 'Compteur d\'actions',
          value: 'compteur_actions',
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      // filter: { place_holder: 'Compteur d\'actions...', type: 'number' },
      functions: [
        (th, td) => {

        },
      ],
      filters: [
        {
          key: 'compteur_actions',
          text: 'Nombre d\'actions',
          place_holder: 'Nombre d\'actions',
          type: 'select',
          multiple: true,
          values: [],
          id: 'id',
          label: 'label',
          column: 'compteur_actions',
          noLimit: true,
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication_compteur_actions',
          column: 'compteur_actions',
        },
      ],
    },

    /*{
      text: 'Videomaker plus soundcloud',
      value: 'videomaker_plus_soundcloud',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Videomaker plus soundcloud" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'videomaker_plus_soundcloud',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'videomaker_plus_soundcloud',
          text: 'Videomaker plus soundcloud',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'videomaker_plus_soundcloud',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-videomaker_plus_soundcloud',
          column: 'videomaker_plus_soundcloud',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },
    {
      text: 'Publication de vidéo YTB',
      value: 'publication_de_video_ytb',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication de vidéo YTB" ?',
      fields: [
        {
          type: 'date',
          label: 'Date de traitement',
          value: 'publication_de_video_ytb',
          required: false,
        },
        {
          type: 'color',
          label: 'Couleur de fond',
          value: 'color_id',
          item_value: 'id',
          item_text: 'color_name',
          items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_de_video_ytb',
          text: 'Publication de vidéo YTB',
          place_holder: 'Date1 - Date2',
          type: 'date',
          menu: false,
          range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'publication_de_video_ytb',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_de_video_ytb',
          column: 'publication_de_video_ytb',
        },
      ],
      blockBg: 'bg-grey',
      functions: [
        canEdit,
      ],
    },*/
    /*
    {
      text: 'Mail d\'envoi DP',
      value: 'mail_envoi_dp',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Mail d\'envoi DP" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'mail_envoi_dp', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors,
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'mail_envoi_dp', text: 'Mail d\'envoi DP', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'mail_envoi_dp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-mail_envoi_dp',
          column: 'mail_envoi_dp',
        },
      ],
    },
    {
      text: 'Mail d\'envoi SP Num',
      value: 'mail_envoi_sp_num',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Mail d\'envoi SP Num" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'mail_envoi_sp_num', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'mail_envoi_sp_num', text: 'Mail d\'envoi SP Num', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'cp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-mail_envoi_sp_num',
          column: 'mail_envoi_sp_num',
        },
      ],
    },
    {
      text: 'Partage FB de vidéos',
      value: 'partage_fb_de_videos',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Partage FB de vidéos" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'partage_fb_de_videos', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'partage_fb_de_videos', text: 'Partage FB de vidéos', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'cp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-partage_fb_de_videos',
          column: 'partage_fb_de_videos',
        },
      ],
    },
    {
      text: 'Envoi du lien BT vidéo',
      value: 'envoi_du_lien_bt_video',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Envoi du lien BT vidéo" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'envoi_du_lien_bt_video', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'envoi_du_lien_bt_video', text: 'Envoi du lien BT vidéo', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'cp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-envoi_du_lien_bt_video',
          column: 'envoi_du_lien_bt_video',
        },
      ],
    },
    {
      text: 'Publication ITW P Demeure',
      value: 'publication_itw_p_demeure',
      align: 'center',
      cellClass: 'text-center',
      action: 'edit',
      type: 'date',
      date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
      colors: 'communication_colors',
      comments: 'communication_comments',
      editable: true,
      info: 'Confirmer le traitement de "Publication ITW P Demeure" ?',
      fields: [
        {
          type: 'date', label: 'Date de traitement', value: 'publication_itw_p_demeure', required: false,
        },
        {
          type: 'color', label: 'Couleur de fond', value: 'color_id', item_value: 'id', item_text: 'color_name', items: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
        },
        { type: 'textarea', label: 'Commentaire', value: 'comment' },
      ],
      filters: [
        {
          key: 'publication_itw_p_demeure', text: 'Publication ITW P Demeure', place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        {
          text: 'Couleur de fond',
          place_holder: 'Couleur de fond',
          type: 'select',
          values: store.state.app.colors.filter(el => store.state.app.settings.communication_colors_ids.includes(el.id)),
          label: 'color_name',
          id: 'id',
          multiple: true,
          key: 'communication_colors.column_name',
          join_table: 'communication_colors',
          join_column: 'communication_colors.communication_id',
          local_column: 'communications.id',
          conditions: [
            {
              name: 'communication_colors.column_name',
              value: 'cp',
              single: true,
            },
            {
              name: 'communication_colors.color_id',
              value: null,
              single: false,
            },
          ],
        },
        {
          text: 'Condition',
          place_holder: 'Condition',
          type: 'select',
          is_condition: true,
          values: [
            { id: 0, label: 'Est vide' },
            { id: 1, label: 'N\'est pas vide' },
          ],
          label: 'label',
          id: 'id',
          multiple: false,
          key: 'communication-publication_itw_p_demeure',
          column: 'publication_itw_p_demeure',
        },
      ],
    }, */

  ]
  const communicationTableColumns = [
    ...tableCommonColumns,
    ...communicationTableColumnsForReport,
  ]

  const isSnackbarBottomVisible = ref(false)
  const snackBarMessage = ref('')
  const isFormActive = ref(false)
  const isFormAttributionActive = ref(false)
  const deleteDialog = ref(false)
  const signatureDialog = ref(false)
  const isbnDialog = ref(false)
  const titleDialog = ref(false)
  const accordDialog = ref(false)
  const downloadObject = ref({ url: null, loading: false })
  const searchQuery = ref('')
  const authorFilter = ref(router.currentRoute.params.id)
  const payFilter = ref(null)
  const sourceFilter = ref(null)
  const statusFilter = ref(null)
  const stateFilter = ref(null)
  const tabFilter = ref(null)
  const totalItems = ref(0)
  const loading = ref(false)

  const options = ref({
    rowsPerPage: 50,
    itemsPerPage: 50,
    page: 1,
    sortDesc: [true],
  })
  const filters = ref([])
  const publicationStates = ref({
    CREATED_STATE: 0,
    SEND_STATE: 1,
    RESEND_STATE: 2,
    SIGNED_STATE: 3,
    COLLECTED_STATE: 4,
    TEXT_STATE: 5,
    COVER_STATE: 6,
    SUPERVISOR_STATE: 7,
    PUBLICATION_STATE: 8,
    RESILIATION_STATE: 9,
    PAYMENT_STATE: 9,
  })
  const page = ref(1)
  const pageCount = ref(0)
  const selectedRows = ref([])
  const resetForm = () => {
    if (form.value) form.value.reset()
  }

  // new item
  const openDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) {
      if (!val.action || (!val.action.includes('resiliation') && val.action !== 'paiement_end_of_file' && val.action !== 'paiement_huissier')) {
        return false
      }
    }

    if (val) {
      item.value = val
    } else {
      item.value = blankItem
      resetForm()
    }
    isFormActive.value = !isFormActive.value
  }

  const openDialogAttribution = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    isFormAttributionActive.value = !isFormAttributionActive.value
  }

  const openSignatureDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    signatureDialog.value = !signatureDialog.value
  }

  const openAccordDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    accordDialog.value = !accordDialog.value
  }
  const openIsbnDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    isbnDialog.value = !isbnDialog.value
  }
  const openTitleDialog = val => {
    if (val && val.state >= store.state.app.states.RESILIATION_STATE) return false
    item.value = val
    item.value.manuscript = item.value.manuscript || { title: null }
    item.value.comment = item.value.manuscript.title

    titleDialog.value = !titleDialog.value
  }

  // fetch data
  const fetchItems = (download, downloadType) => {
    accordDialog.value = false
    signatureDialog.value = false
    isFormActive.value = false
    isFormAttributionActive.value = false
    if (download) {
      downloadObject.value = { url: null, loading: true }
    }

    const singleFilters = filters.value.filter(ele => ele.filter != null && ele.filter.value !== '' && ele.filter.value != null && (ele.filter.value.constructor !== Array || ele.filter.value.length > 0)).map(ele => ({
      ...ele.filter,
      key: ele.value,
      values: null,
    }))

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0)
    const selectedMultipleFilters = []
    for (let i = 0; i < multipleFilters.length; i++) {
      selectedMultipleFilters.push(...multipleFilters[i].filters
        .filter(ele => ele.value !== '' && ele.value != null && (ele.value.constructor !== Array || ele.value.length > 0))
        .map(ele => ({ ...ele, values: null/* , type: null */ })))
    }

    const columns = filters.value.filter(ele => (ele.filter != null || ele.filters != null) && !ele.value.includes('.') && !ele.queryExcluded).map(ele => ele.queryColumn || ele.value)
    /*console.log(filters.value.filter(ele => ele.editable === true ).map(ele => ele.value).join(','))*/

    const records_types = filters.value.filter(ele => ele.comment_column).map(ele => ele.comment_column || ele.value)
    const colors_names = filters.value.filter(ele => ele.color_column).map(ele => ele.color_column)

    // const multipleFilters = filters.value.filter(ele => ele.filters != null && ele.filters.length > 0).map(th => th.filters.map(ele => ({ ...ele.filter, key: ele.value, values: null })))
    store
      .dispatch('app-publication/fetchItems', {
        filters: [...singleFilters, ...selectedMultipleFilters],

        /* ...filters.value, */
        term: searchQuery.value,
        state: stateFilter.value,
        status: statusFilter.value,
        tab: tabFilter.value,
        pay: payFilter.value,
        author: authorFilter.value,
        source: sourceFilter.value,
        orderBy: options.value.sortBy ? options.value.sortBy[0] : 'publication_id',
        // eslint-disable-next-line no-nested-ternary
        direction: options.value.sortDesc ? (options.value.sortDesc[0] ? 'DESC' : 'ASC') : 'DESC',
        page: options.value.page,
        download,
        downloadType,
        records_types,
        colors_names,
        per_page: options.value.itemsPerPage,
      })
      .then(response => {
        if (download) {
          downloadObject.value = {
            loading: false,
            url: response.data || null,
          }
        } else {
          const { data, total } = response.data
          items.value = data.map(ele => {
            if (ele.dos) {
              ele.dos = (ele.dos || 0).toFixed(2)
            }
            ele.price = (ele.price || 0).toFixed(2)
            ele.price_usd = (ele.price_usd || 0).toFixed(2)
            ele.amount = (ele.amount || 0).toFixed(2)

            return ele
          })
          totalItems.value = total
        }

        // remove loading state
        loading.value = false

        if (item.value.action === 'collecte_word_file') {
          /* console.log('word_file')
          openDialogAttribution({
            ...item.value, publication_id: item.value.id, gestion_id: null, assistant_id: null, task_date: null,
          }) */
        }
      })
      .catch(error => {
        console.log(error)
      })
      .then(() => {
        loading.value = false
      })
  }
  const canVerify = val => {
    if (val.bat_cover && val.text_bat) {
      if (val.publication_records && val.publication_records.length > 0) {
        const recordsT = val.publication_records.filter(ele => ele.type && ele.type.includes('text_text_bat')).sort((a, b) => b.id - a.id)
        if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != store.state.app.settings.publication_bat_status) {
          return false
        }

        const records = val.publication_records.filter(ele => ele.type && ele.type.includes('bat_cover')).sort((a, b) => b.id - a.id)
        if (records.length === 0 || !records[0].color || !records[0].color.id || records[0].color_id != store.state.app.settings.publication_bat_status) {
          return false
        }

        return true
      }

      return false
    }

    return false
  }
  const isPublicationbod = val => {
    if (val.publishing_bod) {
      if (val.publication_records && val.publication_records.length > 0) {
        const recordsT = val.publication_records.filter(ele => ele.type && ele.type.includes('publishing_bod')).sort((a, b) => b.id - a.id)
        if (recordsT.length === 0 || !recordsT[0].color || !recordsT[0].color.id || recordsT[0].color_id != (store.state.app.settings.publication_bod_status || 1)) {
          return false
        }

        return true
      }

      return false
    }

    return false
  }
  const confirmDelete = val => {
    item.value = val
    deleteDialog.value = true
  }
  const deleteItem = () => {
    if (item.value) {
      loading.value = true
      store.dispatch('app-publication/deleteItem', item.value.id)
        .then(response => {
          loading.value = false
          deleteDialog.value = false
          fetchItems()
          // eslint-disable-next-line no-unused-vars
        }).catch(error => {
        loading.value = false
      })
    }
  }
  const attributeIsbn = () => {
    if (item.value) {
      loading.value = true
      const payload = { id: item.value.id }

      axios.post(`/publications/action/publication_isbn/${item.value.id}`,
        payload)
        .then(response => {
          loading.value = false
          isbnDialog.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)
          fetchItems()
        }).catch(error => {
        loading.value = false
        isbnDialog.value = false
      })
    }
  }
  const changeTitle = () => {
    if (item.value) {
      loading.value = true
      const payload = {
        id: item.value.id,
        title: item.value.manuscript.title,
        comment: item.value.comment,
      }

      axios.post(`/publications/action/publication_title/${item.value.id}`,
        payload)
        .then(response => {
          loading.value = false
          titleDialog.value = false
          if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
            if (response.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
            } else {
              isSnackbarBottomVisible.value = true
              snackBarMessage.value = response.data.message

              // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
            }

            return true
          }

          // emit('update:is-form-active', false)
          fetchItems()
        }).catch(error => {
        loading.value = false
        titleDialog.value = false
      })
    }
  }
  // eslint-disable-next-line no-unused-vars
  let timeout = null
  watch([filters], () => {
    if (timeout) {
      clearTimeout(timeout)
    }
    timeout = setTimeout(() => {
      if (!loading.value) {
        loading.value = true
        selectedRows.value = []
        fetchItems()
      }
    }, 1500)
  }, { deep: true })
  watch([searchQuery, payFilter, sourceFilter, statusFilter, options], () => {
    loading.value = true
    selectedRows.value = []
    fetchItems()
  }, { deep: true })

  const saved = data => {
    items.value[data.index] = data.item
  }

  return {
    publicationStates,
    item,
    signatureForm,
    items,
    saved,
    paymentMethods,
    blockeds,
    communicationTableColumns,
    downloadObject,
    tableCommonColumns,
    searchQuery,
    filters,
    payFilter,
    sourceFilter,
    statusFilter,
    stateFilter,
    tabFilter,
    totalItems,
    loading,
    options,
    selectedRows,
    isFormActive,
    isFormAttributionActive,
    blankItem,
    form,
    page,
    pageCount,
    snackBarMessage,
    isSnackbarBottomVisible,
    signatureDialog,
    isbnDialog,
    titleDialog,
    accordDialog,
    openSignatureDialog,
    openAccordDialog,
    resetForm,
    openDialog,
    openDialogAttribution,
    openIsbnDialog,
    openTitleDialog,
    deleteItem,
    canVerify,
    isPublicationbod,
    deleteDialog,
    confirmDelete,
    fetchItems,
    attributeIsbn,
    changeTitle,
  }
}
