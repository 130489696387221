<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card
      height="100%"
      flat
    >
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">{{ item.id ? $t('Modifier'): $t('Ajouter une permission') }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>

      <v-card-text
        class="overflow-y-auto"
        style="max-height: 500px;"
      >
        <v-expansion-panels>
          <v-expansion-panel
            v-for="tab in tabs"
            :key="tab.tab"
          >
            <v-expansion-panel-header>{{ tab.label }} ({{ tab.columns.length }})</v-expansion-panel-header>
            <v-expansion-panel-content>
              <template v-for="perm in tab.columns">
                <v-list dense>
                  <v-list-group
                    ref="refVListGroup"
                    dense
                    class="vertical-nav-menu-group"
                  >
                    <template #activator>
                      <v-list-item-title>{{ perm.text || perm.value }}</v-list-item-title>
                    </template>
                    <template v-for="(t,ind) in types">
                      <v-list-item dense>
                        <v-list-item-action>
                          <v-checkbox
                            v-model="perm['permissions']"
                            :value="t.value"
                          ></v-checkbox>
                        </v-list-item-action>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ t.text }}
                          </v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-i="ind < types.length" />
                    </template>
                  </v-list-group>
                </v-list>
              </template>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel"></slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
    <v-snackbar
      v-model="isSnackbarBottomVisible"
      :color="successful?$vuetify.theme.currentTheme.success:$vuetify.theme.currentTheme.error"

      dark
    >
      {{ snackBarMessage }}
    </v-snackbar>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
import controller from './Controller'

export default {
  components: { },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {},
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
      items,
      types,
      tabs,
      openDialogGenerate,
    } = controller()

    const errorMessages = ref({ })
    const valid = ref(false)
    const loading = ref(false)
    const successful = ref(false)
    const isLoadingPermissions = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        successful.value = false
        store.dispatch('permission/addItems', { tabs })
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            if (response.data && response.data.length) {
              for (var i = 0; i < response.data.length; i++) {
                const index = items.value.findIndex(ele => ele.id === response.data[i].id)
                if (index < 0) {
                  items.value.push(response.data)
                }
              }
            }

            successful.value = true
            isSnackbarBottomVisible.value = true
            snackBarMessage.value = 'Permissions ajoutées ou mises à jour avec succès'
            openDialogGenerate()
             //emit('update:is-form-active', false)
             emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      successful,
      resetItem,
      form,
      isLoadingPermissions,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,
      items,
      types,
      tabs,
      openDialogGenerate,
      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
